import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import Button from 'reactstrap/lib/Button';
import Collapse from 'reactstrap/lib/Collapse';

import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, NativeSlider, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

// const images = [
//   // require('../public/parcels-slider/ps_shop_slide_vinyl.jpg'),
//   // require('../public/parcels-slider/ps_shop_slide_bundle.jpg'),
//   require('../public/parcels-slider/image-2.jpg'),
//   require('../public/parcels-slider/image-3.jpg'),
//   require('../public/parcels-slider/image-4.jpg'),
//   require('../public/parcels-slider/image-6.jpg'),
//   require('../public/parcels-slider/image-8.jpg'),
// ];

// const links = [
//   // '/product/24924/parcels-live-vol-1',
//   // '/product/24925/live-vol-1-bundle',
//   '/product/18862/birger-t',
//   '/product/18864/member-t',
//   '/product/15574/withorwithout-t',
//   '/product/18863/new-original-t',
//   '/product/18864/member-t',
// ];

const ProductListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();
  const [showCoronaInfo, setShowCoronaInfo] = React.useState(false);

  const toggleCoronaInfo = () => setShowCoronaInfo((previous) => !previous);

  React.useEffect(() => {
    receiveProducts();
  }, [language]);

  return (
    <article className="mb-5">
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page" />
      </Helmet>
      <div className="hero-image d-flex justify-content-center mb-3">
        <img
          src={require('./assets/images/ps_head_overlay.svg')}
          alt="PARCELS"
          title="PARCELS"
          className="hero-image-overlay"
          loading="lazy"
        />
      </div>
      <div className="d-flex justify-content-center mb-3">
        <img
          src={require('./assets/images/dance.gif')}
          alt="PARCELS"
          title="PARCELS"
          className="hero-image-mascot"
          loading="lazy"
        />
      </div>
      {/* <NativeSlider images={images} links={links} className="hero-slider mb-4" /> */}
      <div className="marquee">
        <div>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
          <span className="mr-5">{'Choose Love.'}</span>
        </div>
      </div>
      {/* <Container className="list-page mt-5">
        <div className="row"> */}
      {/* <div className="aussie-preorder col-12 mb-4">
            <a href="/tickets" className="">
              <span className="pr-2">{'TICKETS FOR PARCELS IN CONCERT 2022'}</span>
              <i className="fal fa-long-arrow-alt-right"></i>
            </a>
          </div> */}
      {/* <div className="aussie-preorder col">
            <a href="/products?categoryId=2132#product-listing">
              <span className="pr-2">{'Order new album worldwide'}</span>
              <i className="fal fa-long-arrow-alt-down"></i>
            </a>
          </div>
          <div className="aussie-preorder col">
            <a href="https://store.sound-merch.com.au/collections/parcels" className="outline">
              <span className="pr-2">{'Order new album Australia'}</span>
              <i className="fal fa-long-arrow-alt-right"></i>
            </a>
          </div>
        </div>
      </Container> */}
      <Container>
        {/* <h1 className="text-center mb-0 mt-5">{t(`PRODUCTS.TITLE`)}</h1> */}
        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter((p) => p.tag_ids.includes(8572))}
            filters="off"
            showSubtitle
            // itemClassName="random-rotation-object"
            // itemImageClassName="scribble-hover-overlay-object"
          />
        )}
      </Container>
      <Container className="list-page mb-3 d-none">
        <div className="aussie-preorder">
          <a href="https://store.sound-merch.com.au/collections/parcels">
            <strong>{'AUSSIES!'}</strong>
            {' Day/Night can also be preordered here'}
          </a>
        </div>
      </Container>
      <Container className="list-page mt-5 d-none">
        <Alert color="info">
          <p className="alert-heading text-center m-0">
            <Button color="link" block onClick={toggleCoronaInfo}>
              <i className="fal mr-2" />
              {'⚠️ Shipping outside of Europe during Corona pandemic ⚠️'}
              <i className={`fal fa-chevron-${showCoronaInfo ? 'up' : 'down'} ml-2`} />
            </Button>
          </p>
          <Collapse isOpen={showCoronaInfo}>
            <hr />
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <p>
              Due to the worldwide Corona pandemic it is currently not possible to ship items across the globe as fast
              as we used to. Currently shipments to USA, Canada, Australia and a few other countries outside of Europe
              can take up to 90 days until delivery. However please be reassured that we did not forget your order and
              it will be delivered as soon as possible. Thank you all for your patience!
            </p>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <p>
              Please find further information regarding shipments to your country here:
              <br />
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <a href="https://www.deutschepost.de/en/c/coronavirus.html" target="_blank" rel="noreferrer">
                https://www.deutschepost.de/en/c/coronavirus.html
              </a>
            </p>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <p>
              The good news:
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <br />
              From now on we provide UPS Express as a new shipment option which makes it possible, to deliver your order
              within just a few days. If you really do not want to miss out on the newest items from the Parcels Pop
              Shop, please make sure to choose UPS as shipment method at the checkout for a fast delivery.
              <br />
            </p>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <p>#stayhealthy</p>
          </Collapse>
        </Alert>
      </Container>
      <div className="d-flex justify-content-center mt-5 mb-1">
        <img
          src={require('./assets/images/youngone.gif')}
          alt="PARCELS"
          title="PARCELS"
          className="footer-image-mascot"
          loading="lazy"
        />
      </div>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
